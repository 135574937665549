.page-template-login{
	position: relative;

	section{
		padding: 2rem 0;
	}

	.btn-primary{
		width: 100%;
	}
}