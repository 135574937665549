.cachedDataMessage.alert
{
    position: fixed;
    z-index: $zIndexCacheMessage;
    bottom: 0;
    margin-bottom: 0;
    border: none;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    color: white;
    background: black;
    background: rgba(black, 0.5);
    transition: opacity 200ms ease-in;

    &::after
    {
        display: none;
    }

    &[hidden]
    {
        pointer-events: none;
        display: block !important;
        opacity: 0;
    }
}
