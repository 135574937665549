.product {
    margin: 0;
    position: relative;
    border: 1px solid $light-grey !important;
    border-radius: $borderRadius;

    .product-bestseller{
        position: absolute;
        top: -2rem;
        height: 2rem;
        width: 100%;
        border-radius: $borderRadius $borderRadius 0 0;
        background: $primary;
        color: $white;
        text-align: center;
        font-weight: bold;
        font-size: 1rem;
        line-height: 2rem;
        text-transform: uppercase;
    }

    .card-header, .card-footer, .card-body{
        background: none !important;
        border: none;
        text-align: center;
        padding: 1rem;
    }

    .product-name{
        color: $black;
        font-size: 1.75rem;
        text-transform: uppercase;

        .product__brand{
            font-weight: bold;
        }
    }

    .product-old-price{
        text-align: center;
        display: block;
        text-decoration: line-through;
    }

    .product-save{
        display: block;
        text-align: center;
        font-size: 1.25rem;
        color: $green;

        strong{
            color: $green;
        }
    }

    .product-price{
        display: block;
        text-align: center;
        font-size: 2.5rem;
        font-weight: 600;
        line-height: 1.2;
        color: $black;
    }
}

.card-deck.multi-products {
    flex-flow: row nowrap;
    margin-right: -15px;
    margin-left: -15px;
    overflow-x: auto;

    &:after {
        content: '';
        flex: 0 0 15px;

        @include media-breakpoint-up(sm) {
            flex: 0;
        }
    }

    .product {
        margin-left: 15px;
        min-width: 85%;

        @include media-breakpoint-up(sm) {
            min-width: 260px;
        }
    }
}
