.page-template-article-list {
    .nav-link{
        cursor: pointer;

        &.active{
            &:hover{
                color: $white;
            }
        }
    }

    .card {
        position: relative;
        text-decoration: none;
        border-radius: 0;
        overflow: hidden;
        border: none;
        cursor: pointer;
        
        &.placeholder {
            height: 20rem;
            cursor: default;
        }

        &:hover{
            .thumbImg {
                img{
                    transform: scale(1.2);
                }
            }
        }

        .thumbImg {
            position: relative;
            overflow: hidden;
            border-radius: $borderRadius;

            img {
                transform: scale(1);
                transition: .5s ease-in-out;
            }
        }

        .card-body {
            padding: 12px 0 44px 0;
            
            .category{
                display: block;
                color: $dark-grey;
                font-size: 14px;
                margin: 0 0 8px 0;
            }

            h2{
                font-size: 20px;
            }
        }
    }
}
