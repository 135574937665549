.page-template-achievements {
    main{
        section{
            padding: 2rem 0;
        }

        .featured-achievement{
            position: relative;
            padding: 3.5rem 0 2rem 0;
            background: $black;
            color: $white;
            text-align: center;
            overflow: hidden;

            &.is-achieved{
                img, .bg{
                    opacity: 1; 
                }
            }

            img{
                position: relative;
                margin: 0 0 8px 0;
                opacity: 0.3;
                max-width: 200px;
                height: auto;
                transition: opacity 0.3s;
            }

            .bg{
                position: absolute;
                top: 50%;
                left: 50%;
                max-width: none;
                width: 450px;
                height: 450px;
                margin-top: -225px;
                margin-left: -225px;
                animation: spin 12s infinite;
                animation-timing-function: linear;
                opacity: 0;
                transition: opacity 0.3s;
            }

            .achievement-title{
                position: relative;
                display: block;
                font-size: 16px;
                color: $white;
                font-weight: 700;
            }

            .achievement-date{
                position: relative;
                display: block;
                font-size: 14px;
                color: $middle-grey;
                font-weight: 500;
            }

            @keyframes spin {
                100% {
                    transform:rotate(360deg);
                }
            }
        }

        h2{
            font-size: 16px;
            font-weight: bold;
            color: $black;
            margin: 0 0 20px 0;
        }

        .achievement{
            background-color: transparent;
            border: 1px solid transparent;
            border-radius: $borderRadius;
            text-align: center;
            padding: 8px 4px;
            margin: 0 0 20px 0;

            &.active{
                background-color: $light-grey;
                border: 1px solid $grey;
            }

            img{
                opacity: 0.3;
                margin: 0 0 4px 0;
                max-width: 78px;
                height: auto;
            }

            &.is-achieved{
                img{
                    opacity: 1; 
                }
            }

            .achievement-title{
                display: block;
                font-size: 12px;
                color: $black;
                font-weight: 500;
            }

            .achievement-date{
                display: block;
                font-size: 10px;
                color: $dark-grey;
                font-weight: 500;
            }
        }
    }
}
