.page-template-measurement {
    main{
        .human-body{
            position: relative;
            margin-top: 32px;

            .box{
                position: absolute;
                display: inline-block;
                background: rgba($light-grey, 0.75);
                border-radius: $borderRadius;
                padding: 1rem;
                font-size: 16px;
                color: $font-color;
                text-decoration: none;
                min-width: 110px;
                max-width: 130px;

                &:nth-child(2){
                    top: 20px;
                    left: 0;
                    text-align: left;

                    .change-before{
                        display: none;
                    }
                }

                &:nth-child(3){
                    top: 20px;
                    right: 0;
                    text-align: right;

                    .change-after{
                        display: none;
                    }
                }

                &:nth-child(4){
                    top: 35%;
                    left: 0;
                    text-align: left;

                    .change-before{
                        display: none;
                    }
                }

                &:nth-child(5){
                    top: 35%;
                    right: 0;
                    text-align: right;

                    .change-after{
                        display: none;
                    }
                }

                &:nth-child(6){
                    top: 65%;
                    left: 50%;
                    width: 130px;
                    text-align: center;
                    margin-left: -65px;

                    .change-after{
                        display: none;
                    }
                }

                h4{
                   font-weight: 14px;
                   font-weight: 600;
                   color: $primary; 
                   margin: 0 0 4px 0;
                }

                span.measure{
                    display: block;
                    font-size: 16px;
                    font-weight: 800;
                    color: $black;
                }

                span.prefix{
                    color: $middle-grey;
                    font-size: 14px;
                    font-weight: 600;
                }
            }
        }

        h2{
            font-size: 20px;
            margin: 0.5rem 0 1.5rem 0;
        }
        
        hr{
            border-top: 1px solid $light-grey2;
            margin: 1rem 0;
        }

        footer{
            position: sticky;
            bottom: 0;
            width: 100%;
            padding: 8px 0 !important;
            background: $light-grey;
            border-top: none;
            z-index: 3;
        }
    }
   
   
    table {
        font-size: 1rem;
        cursor: pointer;
        padding: 1rem 0 0 0;
        max-width: 1140px;
        margin: 0 auto 1rem auto;

        &.table{
            th, td{
                border-top: none;
                border-bottom: 1px solid $light-grey2;
            }
        } 

        th {
            font-weight: normal;
            color: $font-color
        }

        td{
            color: $font-color;

            strong{
                color: $black;
                font-weight: bold;
            }
            
        }

        a {
            //display: table-row;
            color: inherit;
        }

        .edit-icon {
            color: $secondary;
            font-size: 1rem;
        }

        a:hover {
            text-decoration: none;
        }
    }
}
