.page-template-hydration
{
    main{
        padding: 1rem 0;

        @include media-breakpoint-up(sm) {
            padding: 2rem 0;
        }
    }

    p{
        color: $black;
    }

    .drop{
        position: relative;
        display: inline-block;

        &.placeholder{
            filter: grayscale(100%);
        }

        img{
            position: relative;
            display: block;
            margin: 0 auto;
            z-index: 1;
        }
        
        .value{
            position: absolute;
            top: 1%;
            left: 5%;
            width: 90%;
            height: 98%;
            overflow: hidden;

            .waves{
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 0%;
                max-height: 200%;
                opacity: 0.5;
                
                z-index: 0;
                background-image: url(../../assets/icons/hydration/waves2.png);
                background-position: top right;
                transition: all 1s ease-out;

                animation-name: wave;
                animation-duration: 6s;
                animation-iteration-count: infinite;
                animation-timing-function: linear;

            }

            .waves2{
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 0%;
                max-height: 200%;
                
                z-index: 0;
                background-image: url(../../assets/icons/hydration/waves2.png);
                background-position: top left;
                transition: all 1s ease-out;

                animation-name: wave2;
                animation-duration: 3s;
                animation-iteration-count: infinite;
                animation-timing-function: linear;

            }

            @keyframes wave {
                from {
                    background-position: top right;
                }
                to {
                    background-position: top right 400px;
                }
            }

            @keyframes wave2 {
                from {
                    background-position: top left;
                }
                to {
                    background-position: top left 400px;
                }
            }

            .text{
                position: absolute;
                top: 22%;
                left: 50%;
                z-index: 1;
                margin-left: -12px;

                img{
                    max-width: 24px;
                }
            }
        }
    }

    .goal{
        strong{
            font-size: 20px;
            font-weight: 800;
            color: $black;
        }

        font-size: 14px;
        font-weight: 500;
        color: $middle-grey;

    }

    .btn-goal{
        margin-top: 3rem;
        text-align: left;
        border-radius: $borderRadius;
        background: $input-bg;
        border: 1px solid $input-border;
        color: $black;
        font-weight: 400;
        padding-right: 100px;

        .your-goal{
            color: $primary;
            font-weight: 500;
        }

        svg,
        .SVGInline {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            padding: 0.75rem;
            pointer-events: none;
            max-width: 3.5rem;
            z-index: 3;
        }

        &.btn-placeholder {
            background: $light-grey;
            background-image: linear-gradient(
                to right,
                $light-grey 0%,
                darken($light-grey, 4%) 20%,
                $light-grey 40%,
                $light-grey 100%
            );
            background-repeat: no-repeat;
            background-size: 960px 480px;
            border: none;
            animation-duration: 1s;
            animation-fill-mode: forwards;
            animation-iteration-count: infinite;
            animation-name: placeholderShimmer;
            animation-timing-function: linear;
    
            color: transparent !important;

            .your-goal{
                color: transparent;
            }
        }
    }

    .add-btn{
        padding: 7px 0px 7px 14px;
        margin: 0 0 12px 0;
        width: 100%;
        background-color: $white;
        border: 1px solid $light-grey;
        font-size: 14px;
        color: $navy-blue;
        text-align: left;
        background-image: url('../../assets/icons/hydration/add_icon.svg');
        background-repeat: no-repeat;
        background-position: right 10px center;

        &:active, &:hover{
            background-color: $light-grey !important;
            border-color: $light-grey !important;
            color: $navy-blue !important;
        }

        &.btn-placeholder {
            color: transparent;
            background: $light-grey;
            background-image: linear-gradient(
                to right,
                $light-grey 0%,
                darken($light-grey, 4%) 20%,
                $light-grey 40%,
                $light-grey 100%
            );
            background-repeat: no-repeat;
            background-size: 960px 480px;
        
            img{
                opacity: 0;
            }
        }
    }

    .delete{
        text-decoration: none;
        font-weight: 500;
        font-size: 14px;
        
        &:disabled{
            opacity: 0;
        }
    }
}

.modal-dialog.hydration{

    .close{
        opacity: 1;
    }

    .modal-content{
        border-radius: 10px;
        border: none;

        .modal-header{
            border-bottom: none;
        }

        .modal-body{
            padding: 0 20px;
        }

        .modal-footer{
            border-top: none;
            justify-content: center;
            padding: 20px;
        }
    }

    h2 {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 2rem;

        @include media-breakpoint-up(md){
            font-size: 36px;
        }
    }

    h3 {
        color: $black;
        font-size: 24px;
        font-weight: 500;

        .big-number {
            font-size: 36px;
            vertical-align: baseline;
            font-weight: 800;
            color: $black;
        }
    }

    p{
        color: $medium-grey;
    }

    .btn-primary{
        min-width: rem-calc(150);
    }

    .custom-range {
        position: relative;
        -webkit-appearance: none;
        appearance: none;
        width: 100%;
        height: 46px;
        outline: none;
        padding: 0 !important;
    
        &::-webkit-slider-runnable-track {
            width: 100%;
            height: 10px;
            cursor: pointer;

            background: linear-gradient(
                to right,
                #396D9C 0%,
                #396D9C var(--value),
                $light-grey2 var(--value),
                $light-grey2 100%
            );
            border-radius: 5px;
        }
    
        &::-moz-range-track {
            width: 100%;
            height: 10px;
            cursor: pointer;
            background: linear-gradient(
                to right,
                #396D9C 0%,
                #396D9C var(--value),
                $light-grey2 var(--value),
                $light-grey2 100%
            );
            border-radius: 5px;
        }
    
        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 24px;
            height: 24px;
            background: $navy-blue;
            border: 6px solid $navy-blue;
            cursor: pointer;
            margin-top: -8px;
        }
    
        &::-moz-range-thumb {
            width: 22px;
            height: 22px;
            background: $navy-blue;
            border: 6px solid $navy-blue;
            cursor: pointer;
            margin-top: -10px;
        }
    
        &::before {
            content: attr(min) " " attr(unit);
            position: absolute;
            left: 0;
            bottom: -0.5rem;
            font-size: 14px;
            color: $middle-grey;
            font-weight: 500;
        }
    
        &::after {
            content: attr(max) " " attr(unit);
            position: absolute;
            right: 0;
            bottom: -0.5rem;
            font-size: 14px;
            color: $middle-grey;
            font-weight: 500;
        }
    }
}
