h1, .heading-1 {
    color: $navy-blue;
    font-size: 24px;
    font-weight: 800;
    margin-bottom: 1rem
}

h2, .heading-2 {
    color: $black;
    font-size: 20px;
    font-weight: 800;
    margin-bottom: 1rem;

    &.primary{
        color: $primary;
    }
}

h3, .heading-3 {
    color: $black;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 12px;

    &.primary{
        color: $primary;
    }
}

h4, .heading-4{
    font-size: 1rem;
    color: $black;
    font-weight: bold;
    margin-bottom: 1rem;
    -webkit-font-smoothing: antialiased;
}

p {
    font-size: 14px;
    color: $font-color;

    &.stronger{
        color: $black;
    }

    small{
        font-size: 12px;
    }

    &.semi-bold {
        font-weight: 600;
    }
}

a {
    word-wrap: break-word;

    &.grey {
        color: $grey;
    }
}

ul.list {
    font-size: 1.5rem;
    padding: 0;
    list-style: none;

    li {
        position: relative;
        padding: 0 0 0 2rem;

        &:before {
            content: '';
            position: absolute;
            top: 0.5rem;
            left: 0;
            width: 1rem;
            height: 1rem;
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100%;
        }
    }

    &.grey-dots {
        li {
            padding: 0 0 0 1rem;

            &:before {
                top: 10px;
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background-color: $dark-grey;
            }
        }
    }
}
