//colors
$black: #000000;
$white: #fff;

$red: #FE2828;
$green: #15B86A;
$blue: #48b3e3;

$navy-blue: #074884;

$dark-grey: #5F5F5F;
$medium-grey: #999999;
$middle-grey: #9E9E9E;
$grey: #DBDBDB;
$light-grey: #F8F8F8;
$light-grey2: #ECECEC;

//descriptive colors
$font-color: $dark-grey;
$primary: $red;
$input-border: $grey;
$input-bg: $light-grey;

//env indicator
$devColor: $blue;
$stageColor: $green;
$prodColor: $red;

// In italics “v” from “dev” sticks out from the container tad too much
$italicDevCompensation: 1.1rem;
$zIndexEnvIndicator: 16777271;
$zIndexCacheMessage: 1001;
$zIndexFloatingACtion: 1001;
$zIndexFooter: 1000; // .fixed-top (main menu) has 1030
$zIndexMenu: 100;
$zIndexTimeline: 5;
$zIndexLoader: 1;

$borderRadius: 4px;
