.page-template-cms-article {
    main{
        header {
            padding: 1rem 0 0 0;
    
            h1 {
                text-align: left;
                font-size: 30px;
                color: $black;
            }

            .category{
                display: block;
                color: $dark-grey;
                font-size: 14px;
                margin: 0 0 8px 0;
            }
        }
    
        .article-img{
            overflow: hidden;
            border-radius: $borderRadius;
            margin: 0 0 1rem 0;
        }

        img{
            border-radius: $borderRadius;
            margin: 1rem 0;
        }

        p{
            font-size: 16px;
            margin: 0 0 1rem 0;

            b{
                font-weight: 600;
            }
        }

        hr {
            margin: 1.5rem 0 2rem 0;
        }
    
        ul {
            padding: 0;
            list-style: none;
            margin: 8px 0 16px 0;
    
            li {
                position: relative;
                font-size: 16px;
                padding: 0 0 0 26px;
                margin: 0 0 8px 0;

                &::before{
                    content: '';
                    position: absolute;
                    width: 8px;
                    height: 8px;
                    background: $navy-blue;
                    top: 8px;
                    left: 8px;
                    border-radius: 50%;
                }
            }
        }
    
        ol {
            font-size: 16px;
            padding: 0 0 0 26px;
            margin: 8px 0 16px 0;
    
            li {
                padding: 0;
                margin: 0 0 8px 0;

                ul {
                    padding: 0;
                    list-style: none;
                    margin: 8px 0 16px 0;
            
                    li {
                        position: relative;
                        font-size: 16px;
                        padding: 0 0 0 26px;
                        margin: 0 0 8px 0;
        
                        &::before{
                            content: '';
                            position: absolute;
                            width: 8px;
                            height: 8px;
                            background: $navy-blue;
                            top: 8px;
                            left: 8px;
                            border-radius: 50%;
                        }
                    }
                }
            }
        }
    
        .blockquote {
            position: relative;
            margin-left: 3.5rem;
            padding-left: 1rem;
            border-left: 2px solid $primary;
    
            &:before {
                content: '\201D';
                position: absolute;
                top: -2rem;
                left: -3.5rem;
                color: $primary;
                font-size: 6.25rem;
            }
        }

        table{
            font-size: 14px;

            th, td{
                padding: 0.25rem;
            }

            p, ol, ul{
                font-size: 14px;
            }
        }
    
        .article-lead,
        .article-summary {
            font-size: 16px;
            color: $font-color;
            margin: 0 0 1rem 0;
        }
    
        strong{
            color: $black;
        }

        // CMS CONTENT
        .cms-content {
            h3 {
                margin: 0 0 1rem 0;
            }

            .blockquote {
                position: relative;
                margin-left: 3.5rem;
                padding-left: 1rem;
                border-left: 2px solid $primary;
    
                &:before {
                    content: '\201D';
                    position: absolute;
                    top: -2rem;
                    left: -3.5rem;
                    color: $primary;
                    font-size: 6.25rem;
                }
            }
    
            .collapse-item {
                border-bottom: 1px solid $light-grey;
        
                &:last-child {
                    border-bottom: none;
                }
            }
        
            h3 {
                margin: 1rem 0;
                padding-right: 2rem;
                position: relative;
        
                button{
                    background: none;
                    border: none;
                    color: $black;
                    font-weight: 500;
                    text-align: left;
                    display: block;
                    width: 100%;
                    outline: none;
                    padding: 0;
                }
        
                span.collapse-arrow {
                    display: flex;
                    align-items: center;
                    position: absolute;
                    right: 0;
                    top: 0;
                    color: $primary;
                    height: 100%;
                    transition: all 0.3s ease-in-out;
        
                    &.open {
                        transform: rotate(180deg);
                    }
                }
            }
        
            .card {
                border: none;
                margin-bottom: 1rem;
        
                .card-body {
                    background: $light-grey;
                    border-radius: $borderRadius;
                    padding: 1rem;
                }
            }
    
            .pageActions {
                text-align: center;
            }
    
            aside {
                &.right {
                    float: right;
                    margin-left: 1rem;
                    margin-bottom: 1rem;
                }
    
                &.left {
                    float: left;
                    margin-right: 1rem;
                    margin-bottom: 1rem;
                }
            }
        }
        
    }
}
