.btn{
    &.btn-primary{
        position: relative;
        // background: $primary-gradient;
        font-size: 16px;
        line-height: 18px;
        font-weight: 700;
        color: $white;
        border: 1px solid $primary;
        border-radius: $borderRadius;
        padding: 12px 20px;
        transition: all 0.3s;
    }

    &.btn-outline-primary{
        background: transparent;
        font-size: 16px;
        line-height: 18px;
        font-weight: 700;
        color: $primary;
        border: 2px solid $primary;
        border-radius: $borderRadius;
        padding: 10px 20px;

        &:active{
            background-color: transparent !important;
            border-color: $primary !important;
            color: $primary !important;
        }

        &.active{
            color: $white !important;
        }
    }

    &.btn-secondary{
        position: relative;
        // background: $secondary-gradient;
        font-size: 16px;
        line-height: 18px;
        font-weight: 700;
        color: $white;
        border: none;
        border-radius: $borderRadius;
        padding: 12px 20px;
        transition: all 0.3s;
        border: none;
    }

    &.btn-outline-secondary{
        background: transparent;
        font-size: 16px;
        line-height: 18px;
        font-weight: 700;
        color: $secondary;
        border: 2px solid $secondary;
        border-radius: $borderRadius;
        padding: 10px 20px;

        &:active{
            background-color: transparent !important;
            border-color: $secondary !important;
            color: $secondary !important;
        }

        &.active{
            color: $white !important;
        }
    }

    &.btn-white{
        background: $white;
        font-size: 18px;
        font-weight: bold;
        line-height: 1.5;
        color: $primary;
        border: 2px solid transparent;
        border-radius: $borderRadius;
        padding: 6px 20px;
    }

    &.btn-outline-white{
        background: transparent;
        font-size: 18px;
        font-weight: bold;
        line-height: 1.5;
        color: $white;
        border: 2px solid $white;
        border-radius: $borderRadius;
        padding: 6px 20px;

        &:active{
            background-color: transparent !important;
            border-color: $white !important;
            color: $white !important;
        }
    }

    &.btn-social{
        display: block;
        margin: 0.5rem auto;
        font-size: 14px;
        font-weight: 400;

        &:before{
            content: ' ';
            position: relative;
            display: inline-block;
            width: 24px;
            height: 24px;
            margin: -3px 10px -7px 0;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
        }
    }

    &.facebook{
        background: #4267b2;
        border: 1px solid #4267b2;
        color: $white;

        &:before{
            background-image: url(../assets/icons/facebook-f-brands.svg);
        }
    }

    &.google{
        background: $white;
        color: $black;
        border: 1px solid $black; 

        &:before{
            background-image: url(../assets/icons/button_google_logo.svg);
        }
    }

    &.apple{
        background: $black;
        color: $white;
        border: 1px solid $black;

        &:before{
            background-image: url(../assets/icons/button_apple_logo.svg);
        }
    }

    &.prev{
        background: none;
        border: none;

        &:active{
            background-color: transparent !important;
        }    
    }

    &.btn-arrow{
        padding: 0;
        position: relative;
        color: $black;
        background-color: $white;
        font-weight: 300;
        text-align: left;

        .SVGInline {
            color: $primary;
            float: right;
        }
    }

    &.btn-placeholder {
        position: relative;
        font-size: 16px;
        line-height: 18px;
        font-weight: 500;
        border: none;
        border-radius: $borderRadius;
        padding: 12px 20px;
        transition: all 0.3s;
        border: none;

        background: $light-grey;
        background-image: linear-gradient(
            to right,
            $light-grey 0%,
            darken($light-grey, 4%) 20%,
            $light-grey 40%,
            $light-grey 100%
        );
        background-repeat: no-repeat;
        background-size: 960px 480px;

        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: placeholderShimmer;
        animation-timing-function: linear;

        color: transparent;
    }

    &:focus {
        box-shadow: none !important;
    }
}
