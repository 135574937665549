.page-template-settings {
    main{    
        header {
            padding: 2rem 0 0 0;
        }

        .container{
            margin-bottom: 24px;
        }

        h2{
            a{
                font-weight: 500;
            }
        }

        h3{
            font-size: 14px;
            font-weight: 700;
            color: $font-color;
        }

        .push{
            min-height: 50vh;
        }

        footer{
            position: sticky;
            bottom: 0;
            width: 100%;
            padding: 8px 0 !important;
            background: $light-grey;
            border-top: none;
            z-index: 3;
        }
    }
}
