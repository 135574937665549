@import './elements/range';

.form-group{
    &.border-bottom{
        padding: 0 24px 8px 24px;
        margin: 0 0 8px 0;
        border-bottom: 1px solid $light-grey !important;
        margin-left: -24px;
        margin-right: -24px;
    }

    label{
        color: $black;
        font-size: 14px;
        font-weight: 500;
        display: block;
        text-align: left;

        sup{
            color: $red
        }
    }
    .form-control{
        padding: 0;
        border-radius: $borderRadius;
        background: $input-bg;
        border: 1px solid $input-border;
        height: auto;
        overflow: hidden;

        &.is-invalid{
            border: 1px solid $red;
        }

        &.select{
            // border: 2px solid $primary;
            // background: $white;

            .SVGInline {
                color: $black;
            }
        }
    
        input{
            border: none;
            background: none;
            padding: 0.5rem 1rem;
            min-height: 40px;
            box-shadow: none !important;

            &:disabled{
                cursor: not-allowed;
            }

            &::-webkit-date-and-time-value{ 
                text-align:left;
            }
        }

        textarea{
            border: none;
            background: none;
            padding: 0.5rem 1rem;

            &:disabled{
                cursor: not-allowed;
            }
        }

        .input-group-text {
            background: none;
            border: none;
            color: $middle-grey;
        }

        .input-placeholder{
            flex: 1 1 auto;
            padding: 0.5rem 1rem;
        }
    }

    .invalid-feedback{
        color: $red;

        li{
            list-style: none;
        }
    }

    svg,
    .SVGInline {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        padding: 0.75rem;
        pointer-events: none;
        max-width: 3.5rem;
        z-index: 3;
    }

    &.is-invalid{
        .invalid-feedback{
            display: block;
        }
    }
}

select {
    padding: 0.5rem 1rem !important;
    border: none !important;
    border-radius: $borderRadius !important;
    background: transparent !important;
    appearance: none;
    height: auto !important;
    cursor: pointer;

    &::-ms-expand {
        display: none;
    }
}

.form-check {
    .form-control{
        border: none;
        height: auto;

        &.is-invalid{
            background: none;
        }
    }
}

.invalid-feedback{
    color: $red;

    li{
        list-style: none;
    }
}

.alert{
    border-radius: 8px;
    background-color: $white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    text-align: left;
    
    &.alert-info, &.alert-success{
        border: 1px solid $primary;
        color: $primary;
    }

    &.alert-danger{
        border: 1px solid $primary;
        color: $primary;
    }

    &.alert-primary{
        border: 1px solid $primary;
        background: #FFF3E5;
        color: $font-color;
    }

    &.alert-secondary{
        border: 1px solid $primary;
        background: $white;
        color: $font-color;
    }

    h2{
        font-size: 16px;
        font-weight: 600;
    }

    h4{
        font-size: 1rem;
        font-style: italic;
        font-weight: bold;
    }

    p{
        color: inherit
    }

    ul{
        font-size: 1rem;
        list-style: disc;
        padding-left: 1.25rem;
        margin: 0;

        li{
            padding: 0;
            &:before{
                display: none;
            }
        }
        
    }
}

.custom-range {
    position: relative;
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 46px;
    outline: none;
    padding: 0;

    &::-webkit-slider-runnable-track {
        width: 100%;
        height: 10px;
        cursor: pointer;
        background: linear-gradient(
            to right,
            rgba($primary, 0.2) 0%,
            $primary var(--value),
            $light-grey var(--value),
            $light-grey 100%
        );
        border-radius: 5px;
    }

    &::-moz-range-track {
        width: 100%;
        height: 10px;
        cursor: pointer;
        background: linear-gradient(
            to right,
            rgba($primary, 0.2) 0%,
            $primary var(--value),
            $light-grey var(--value),
            $light-grey 100%
        );
        border-radius: 5px;
    }

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 30px;
        height: 30px;
        background: $white;
        border: 4px solid $orange;
        cursor: pointer;
        margin-top: -10px;
    }

    &::-moz-range-thumb {
        width: 22px;
        height: 22px;
        background: $white;
        border: 4px solid $orange;
        cursor: pointer;
        margin-top: -10px;
    }

    &::before {
        content: attr(min);
        position: absolute;
        left: 0;
        bottom: -0.5rem;
        font-size: rem-calc(14);
        color: $font-color;
    }

    &::after {
        content: attr(max);
        position: absolute;
        right: 0;
        bottom: -0.5rem;
        font-size: rem-calc(14);
        color: $font-color;
    }
}

.custom-radio{
    padding-left: 0;
    padding-right: 1.5rem;

    .custom-control-input {
        right: 0;
        left: initial;
    }

    .custom-control-label{
        color: $font-color;
        font-size: 16px;
        font-weight: 400;
            
        &:before{
            left: initial;
            right: -1.5rem;
            background-color: $white;
            border: $grey solid 2px;
            width: 18px;
            height: 18px;
        }

        &:after{
            left: initial;
            right: -1.5rem;
            width: 18px;
            height: 18px;
        }
    }

    .custom-control-input:checked ~ .custom-control-label{
        &::before {
            color: $black;
            border-color: $primary;
            background-color: $primary;
        }

        &:after{
            background-image: none;
            background-color: $primary;
            border-radius: 50%;
            margin: 2px;
            width: 14px;
            height: 14px;
            border: 2px solid $white;
        }
    }
}

.custom-switch{
    padding-left: 0;
    padding-right: 50px;

    .custom-control-input {
        right: 0;
        left: initial;
    }

    .custom-control-label{
        color: $font-color;
        font-size: 16px;
        font-weight: 400;

        &::before{
            left: initial;
            top: -2px;
            right: -52px;
            width: 50px;
            height: 28px;
            border-radius: 16px;
            border: none;
            background-color: $grey;
        }

        &::after{
            top: 0px;
            left: initial;
            right: calc(-50px + 22px);
            width: 24px;
            height: 24px;
            border-radius: 50%;
            border: none;
            background-color: $white;
        }
    }

    .custom-control-input:checked ~ .custom-control-label::after {
        background-color: $white;
        transform: translateX(22px);
    }
}